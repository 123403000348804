.conversationList {
  padding: 10px 0px 10px 0px;
  margin-top: 5px;
  width: 100%;
}
.conversations:active {
  background: #ffffff !important;
  border-radius: 12px;
}
.conversations {
}
/* .text-messages{
  padding:15px;
} */
.conversations {
  background: #f8fcff;
  /* box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.1); */
}
#side-bar {
  height: 100vh;
  background: #f8fcff;
  box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.1);
}
.cs-search__input {
  padding: 10px;
}

/* .cs-search{
    background-color: #F6FBFF;
} */
.loader {
  /* display: inline-block; */
  position: relative;
  top: 40%;
  left: 40%;
  width: 0px;
}
.imageLoader {
  width: 100%;
}

/* 
 .image{
     display: flex;
 margin-top: 120px;
    justify-content: center; 
    width: 70%;
    height: 300px;
    margin-left: 100px;
}  */
/* .welcome{
   
    width: 60%;
    display: flex;
    justify-content: end; 
   align-items: center;
  
    
} */

.activeName {
  /* text-align: center; */
}
.dropdown-item {
  cursor: pointer;
}
.cs-message__content {
  margin: 5px;
  background: #f8fcff !important;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 18px !important;

  padding: 10px !important;
}
.chat-container {
  background: #ffffff !important;
}
/* .message-time{
  display: flex;
  justify-content: space-between;
} */
.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;
}

.scrollbar-container {
  /* height: 100vh; */
}
.account-setting {
  text-decoration: none;
  color: black;
}
.message-time {
  float: right;
  font-size: 11px;
  margin-left: 10px;
}
.active {
  background-color: white;
}
.active:hover {
  background-color: white;
}
a {
  text-decoration: none;
}
.channelImage {
  width: 100vw !important;
}

.__react_modal_image__medium_img {
  max-width: 50% !important;
  /* max-height: 100vh; */
  margin-left: 10%;
  /* margin: 10px;
    margin-top: 50px; */
}

@media only screen and (max-width: 768px) {
  .scrollbar-container.cs-sidebar.cs-sidebar--left.sidebar.ps {
    /* display: flex; */
    /* justify-content: center; */

    width: 100% !important;
  }
  .scrollbar-container.cs-sidebar.cs-sidebar--left.sidebar.ps.ps--active-x {
    width: 100% !important;

    display: none;
  }

  .image {
    display: none;
  }

  .__react_modal_image__medium_img {
    max-width: 400px !important;
    max-width: 100vh;
    margin: 10px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .__react_modal_image__medium_img {
    /* max-width: 400px !important; */
    max-width: 100% !important;
    max-width: 100vh;
    margin: 1px;
    margin-top: 20px;
  }
}

.cs-conversation__unread {
  box-sizing: border-box;
  position: absolute;
  right: 21px;
  top: 20px;
  padding: 0.01em 0.3em;
  border-radius: 0.3em;
  font-size: 0.75em;
  font-weight: 600;
  color: #fff;
  /* background-color: #ec1212; */
  max-width: 30em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 100;
  background: dodgerblue;
  border-radius: 23px;
  padding: 4px 10px;
}

.cs-message-list {
  height: 85% !important;
}

.cs-message-list__scroll-wrapper > .cs-message,
.cs-message-list__scroll-wrapper > .cs-message-group {
  max-width: 100% !important;
}

div.popup-content.channelImage-content div {
  justify-content: center !important;
  text-align: -webkit-center;
  /* background: black;
  opacity: 0.2; */
}
div.popup-content.channelImage-content {
  background: transparent;
  /* background: black;
  opacity: 0.2; */
  border: 0;
  text-align: center !important;
}

.popup-overlay.channelImage-overlay {
  overflow: auto;
}

.popup-content.channelImage-content {
  width: 100%;
}

.cs-message__custom-content img {
  width: 100%;
}

.react-transform-component.transform-component-module_content__FBWxo img {
  width: 90%;
  margin: 10px 0;
}
.lp-button {
  border: 0;
  font-weight: bold;
  background: whitesmoke;
  color: black;
  /* float: right; */
  position: fixed;
  right: 15px;
  top: 16px;
  z-index: 99;
}
