.HA_main_image_div{
    width: 50vmax;
    height: 35vmax;
}
.HA_main_div{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    /* height: 100vh; */
    /* background-color: aqua; */
}
.HA_main_header{
    display: flex;
    justify-content:center ;
    align-items: center;
    width: 100%;
    height: 100px;
    /* background-color: antiquewhite; */
}
.HA_Navbar_text{
    margin: 0;
    font-size: 2em;
    font-family: "Inter",sans-serif;
}
.HA_card_main{
    display: flex;
    justify-content: center;
    align-items: center;
   width: 30vmax;
   height:auto ;
   background-color: blueviolet;
}
.HA_card_main_div{
    background-color: #3397fd;
    padding: 2rem;
    width: 500px;
    margin: 60px auto;
    height: auto;
    border-radius: 16px;
}
.inputdiv {
    background-color: #fff;
    padding: 1.5rem;
    width: 116%;
    border: 1px solid #000;
    border-radius: 16px;
}
.HA_card_text_heading{
    display: flex;
    /* justify-content:center ; */
    align-items: center;
    width: 100%;
    /* height: 50px; */
    padding: 10px;
    /* background-color:brown ; */
}
p{
    margin: 0;
    font-family: "Inter",sans-serif;
}
.HA_card_headind_text_stn{
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
}
.HA_card_info_div{
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0px;
    /* background-color: bisque; */
    /* border-bottom: 1px solid rgb(202, 201, 201); */
}
.HA_card_info_inner_text{
    width: 330px;
    /* padding: 10px; */
    height: auto;
    /* background-color:aqua ; */
    margin-bottom: 10px;
}
.HA_card_info_name{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(195, 194, 194);
}
.HA_reset_main_div{
    width: 100%;
    height: auto;
    /* background-color:aqua ; */
}
.HA_reset_heading_main{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* background-color:azure ; */
}
.HA_reser_heading_text{
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: #4E97FD;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: uppercase
}
.HA_reset_from_main{
    width: 100%;
    padding: 10px6
    /* background-color: blueviolet; */
}
.HA_reset_from_part1{
    width: 100%;
    margin-bottom: 10px;

}
.HA_reset_old_ps_input{
    padding-left: 5px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    border-top-style: none;
  border-right-style: none; 
  /* border-bottom-style: rgb(224, 224, 224); */
  border-left-style: none; 
    height: 40px;
}
.HA_reset_old_ps_input:focus{
outline:none ;
}
.HA_reset_from_old_ps_heading{
    margin-bottom: 5px;
    color: gray;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.HA_reset_btn_main_div{
    margin-bottom: 4%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.HA_reset_btn_main{
    cursor: pointer;
    border-radius: 50px !important;
    border: none !important;
    /* width: 80% !important; */
    background-color: #3397fd !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
    height: 40px !important;
    color: white !important;
}
.HA_reset_btn_main:hover{
    background-color: #2566a7 !important;
    
    transition: 0.5s;
}
.HA_toggle_btn_main{
    display: flex;
    align-items:center ;
    /* background-color:coral ; */
    width: auto;
    height: 50px;
}
.HA_toggle_btn_main_text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(143, 142, 142);
}
.formfields {
    margin-bottom: 10px !important;
}
@media screen and (max-width: 768px) {
    .HA_card_headind_text_stn{
        font-size: 30px;
    }
    .HA_reser_heading_text{
        font-size: 30px;
    
    }
    .HA_card_main_div{
        width: 90%;
     
    }
    .HA_card_info_inner_text {
        width: 285px;
    
    }
    .mb-none {
        display: none;
    }
  }

  .disableInput.disabled input {
    cursor: not-allowed;
    pointer-events: none;
  }
  .back-button{
  width: 200px;
  height: 80px;
  margin: 10px;
  padding: 10px;
  }
 
   @media only screen and (min-width: 768px){ 
    .back-mobile{
       display: none;
    }
  } 

    @media only screen and (max-width: 768px){ 
    .back-mobile{
        width: 100px;
        height: 80px;

        font-size: 2rem;
        /* margin: 5px; */
        /* padding: 5px; */
    }
  } 
  